enum ALL_AVAILABLE_LANGUAGES {
  'de',
  'en',
  'en-NA',
  'es',
  'fr',
  'it',
  'nl',
  'pl',
  'pt',
  'ru',
}

export type LanguageCode = keyof typeof ALL_AVAILABLE_LANGUAGES;
export const AVAIL_LANGUAGES = Object.values(ALL_AVAILABLE_LANGUAGES).filter(v =>
  isNaN(Number(v)),
) as LanguageCode[]; // force it not to be just string[]
